<template>
  <div class="leaflet-container">
    <Breadcrumb class="d-none d-sm-flex px-0" :items="vBreadcrums" />
    <v-container class="upper">
      <Leaflet v-if="leafletId" :leafletId="leafletId" />
    </v-container>
  </div>
</template>
<style lang="scss"></style>
<script>
import Breadcrumb from "@/components/navigation/Breadcrumb.vue";
import Leaflet from "@/components/leaflet/Leaflet.vue";

import clickHandler from "~/mixins/clickHandler";
import downloadDocumentMixin from "~/mixins/downloadDocument";

// import leafletService from "~/service/leafletService";

import get from "lodash/get";
import { mapState } from "vuex";
// import isNumber from "lodash/isNumber";

const header = { fullscreenHeader: 97, mobileHeader: 280, desktopHeader: 300 };

export default {
  // eslint-disable-next-line vue/no-unused-components
  name: "LeafletView",
  components: {
    Breadcrumb,
    Leaflet
  },
  data() {
    return {
      leafletId: null
    };
  },
  mixins: [clickHandler, downloadDocumentMixin],
  computed: {
    ...mapState({
      categories: ({ category }) => category.categoryTree
    }),
    vBreadcrums() {
      // let b = this.breadcrumb;
      let b = [];
      b.push({
        to: { name: "Home" },
        text: "Homepage",
        exact: true
      });
      if (this.leafletId) {
        b.push({
          to: {
            name: "Leaflet",
            params: {
              volantino: this.leafletId
            }
          },
          text:
            this.$route.meta && this.$route.meta.breadCrumb
              ? this.$route.meta.breadCrumb
              : "Dettaglio volantino"
        });
      }

      return b;
    }
  },
  methods: {
    toogleFullscreen() {
      const _this = this;
      if (_this.leafletFullscreen) {
        document.exitFullscreen().then(function() {
          if (_this.$vuetify.breakpoint.mdAndUp) {
            _this.swiperHeight =
              window.innerHeight - header.desktopHeader + "px";
          } else {
            _this.swiperHeight =
              window.innerHeight - header.mobileHeader + "px";
          }
        });
      } else {
        // this.leafletFullscreen = true;
        let elem = this.$refs.leafletViewer;
        var methodToBeInvoked =
          elem.requestFullscreen ||
          elem.webkitRequestFullScreen ||
          elem["mozRequestFullscreen"] ||
          elem["msRequestFullscreen"];
        let _this = this;
        if (methodToBeInvoked)
          methodToBeInvoked.call(elem).then(function() {
            _this.swiperHeight =
              window.screen.height - header.fullscreenHeader + "px";
          });
      }
    }
  },
  metaInfo() {
    return {
      title: get(this.leaflet, "title"),
      meta: [
        {
          vmid: "description",
          name: "description",
          content: get(this.leaflet, "description")
        }
      ]
    };
  },
  mounted() {
    this.leafletId = this.$route.params.leafletId;
  },
  watch: {
    "$route.params.leafletId": async function() {
      if (this.$route.params.leafletId) {
        this.leafletId = this.$route.params.leafletId;
      }
    }
  }
};
</script>
